<template lang="pug">
  div(style="max-width: 720px; margin: auto")
    h1 {{$t('app.title')}}
    p.primary--text {{$t('app.guide')}}
    v-img(contain, height=96, src="favicon.png")
    .py-2

    h3 {{$t('app.event')}}

    Divider
    v-list.py-0(dense)
      Event(:id="eventId")

    Divider
    .py-2

    WIP

</template>

<script>
import Event from '@/views/Events/Event';

export default {
  name: 'Home',

  components: { Event },

  computed: {
    eventId() {
      return Math.max(...Object.keys(this.$db.events));
    }
  }

};
</script>
