<template lang="pug">
  v-list-item(:to="{ path: `/event/${id}`, query: $route.query }", :replace="$vuetify.breakpoint.mdAndUp && $route.name == 'event'")
    .d-block(style="width: 100%")
      .d-flex
        .my-auto.mr-4(style="height: 52px; width: 122px; border-radius: 4.5px; overflow: hidden")
          v-img(:src="`${$sekai.assetHost}/event_story/${event.assetbundleName}/screen_image/banner_event_story.webp`")
        v-list-item-content
          v-list-item-title {{event.name}}
          v-list-item-subtitle {{new Date(event.startAt).toLocaleDateString()}} ~ {{new Date(event.aggregateAt).toLocaleDateString()}}
          v-list-item-subtitle.d-flex
            //- .mr-4(style="width: 91.5px")
            template(v-for="eventCardId, i in eventCardIds")
              .pr-2(v-if="i")
              CardIcon(:id="eventCardId", size=32)
              //- v-list-item-avatar.ma-0(size=32)
              //-   v-img(:src="`${$sekai.assetHost}/thumbnail/chara/${$db.cards[eventCardId].assetbundleName}_normal.webp`")
        
</template>

<script>
import CardIcon from '@/components/CardIcon';

export default {
  name: 'Event',

  props: ['id'],

  components: { CardIcon },

  computed: {
    event() {
      return this.$db.events[this.id];
    },
    eventCardIds() {
      return Object.values(this.$db.eventCards)
        .filter(eventCard => eventCard.eventId == this.id)
        .map(eventCard => eventCard.cardId);
    }
  }
};
</script>
